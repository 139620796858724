export const CONTENT_TYPE_JSON = 1;
export const CONTENT_TYPE_MESSAGE_PACK = 2;

export const contentTypeEnum = {
  [CONTENT_TYPE_JSON]: "JSON",
  [CONTENT_TYPE_MESSAGE_PACK]: "Message Pack",
};

export const V_5 = 5;
export const V_3 = 3;

export const ApiVersionEnum = {
  [V_3]: "V3",
  [V_5]: "V5",
};
