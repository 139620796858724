import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { camelCaseToTitleCase } from "../../../../../../utils/helpers";
import {
  cleanRunnedTest,
  setRunedTests,
  setRunedTestsStatistic,
  setSelectedFilterType,
} from "../../../../../../features/data/dataSlice";
import { authPost } from "../../../../../../client";
import { useNavigate } from "react-router-dom";

const fieldTypes = {
  1: "text",
  2: "number",
  3: "boolean",
};

const ValidationForm = () => {
  const { selectedItems, contentType, apiVersion } = useSelector((state) => state.data);

  const [inProcess, setInProcess] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fields = useMemo(() => {
    const fieldsArray = [];

    selectedItems.forEach((item) => {
      const testFields = item.TestCaseProperties.map((fieldItem) => [
        fieldItem.PropertyName,
        fieldItem,
      ]);

      fieldsArray.push(...testFields);
    });

    const uniqueData = new Map(fieldsArray);
    const fieldsElements = [];

    uniqueData.forEach((item) =>
      fieldsElements.push(
        <Form.Item
          label={camelCaseToTitleCase(item.PropertyName)}
          name={item.PropertyName}
          rules={[
            {
              required: item.IsRequired,
              message: `${camelCaseToTitleCase(
                item.PropertyName
              )} is required !`,
            },
          ]}
        >
          <Input type={fieldTypes[item.TypeId]} size="large" />
        </Form.Item>
      )
    );

    return fieldsElements;
  }, [selectedItems]);

  const onSubmit = async (data) => {
      setInProcess(true);
      dispatch(cleanRunnedTest());
      dispatch(setSelectedFilterType(1))

      for (let i = 0; i < selectedItems.length; i++) {
        const response = await authPost(
          `ActionsV2/${selectedItems[i].title}/${selectedItems[i].CaseId}/V${apiVersion}`,
          { ...data, contentType }
        );

        if (!response) {
          navigate("/");
          return;
        } else {
          dispatch(
            setRunedTests({
              ...response,
              title: selectedItems[i].title,
              caseName: selectedItems[i].DisplayedName,
            })
          );
        }

        if (response.status) {
          dispatch(setRunedTestsStatistic({ type: "passed" }));
        } else {
          dispatch(setRunedTestsStatistic({ type: "error" }));
        }
      }

      setInProcess(false);
    };

  return (
    <Form
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      onFinishFailed={() => console.log("finish fail")}
      autoComplete="off"
    >
      {fields}

      <Form.Item>
        {!!fields.length && (
          <Button
            loading={inProcess}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Submit
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
export default ValidationForm;
