import {createSlice, createAsyncThunk, current} from "@reduxjs/toolkit";
import { authGet } from "../../client";
import { CONTENT_TYPE_JSON, V_5 } from "../../utils/constants";

export const getTestCases = createAsyncThunk("data/fetchData", async (type) => {
  const response = await authGet(`TestCasesV2/testCaseSectionInfo/${type}`);
  return response.Data;
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    contentType: CONTENT_TYPE_JSON,
    apiVersion: V_5,
    validations: [],
    selectedItems: [],
    status: "idle",
    error: null,
    selectedFilterType: 1,
    runnedTestAnswers: [],
    runedTestStatistic: {
      passed: 0,
      error: 0,
    },
    isSimple: true,
  },
  reducers: {
    setSelectedFilterType: (state, action) => {
     state.selectedFilterType = action.payload;
    },
    setAllSelected: (state, action) => {
      const selected = [];

      state.validations.forEach((item) => {
        item.selected = action.payload;
        if(state.isSimple && !item.IsSimple) return

        item.TestCaseDetails.forEach((testCase) => {
          testCase.selected = action.payload;
          if (action.payload) {
            selected.push({ ...testCase, title: item.SectionName });
          }
        });
      });

      state.selectedItems = selected;
    },
    selectSection: (state, action) => {
      const selected = [];

      state.validations.forEach((item) => {
        if (action.payload === item.SectionName) {
          item.TestCaseDetails = item.TestCaseDetails.map((testCase) => {
            selected.push({ ...testCase, title: item.SectionName });

            return {
              ...testCase,
              selected: !item.selected,
            };
          });

          item.selected = !item.selected;

          if (!item.selected) {
            const newSelected = [];

            state.selectedItems.forEach((item) => {
              let isAvailable = false;
              selected.forEach((selItem) => {
                if (item.CaseId === selItem.CaseId) {
                  isAvailable = true;
                }
              });

              if (!isAvailable) {
                newSelected.push(item);
              }
            });

            state.selectedItems = newSelected;
          } else {
            state.selectedItems = [...state.selectedItems, ...selected];
          }
        }
      });
    },
    selectTestCase: (state, action) => {
      let isSelected = false;
      let selectedItemAdded = null;

      state.validations = state.validations.map((item) => {
        if (action.payload.sectionName === item.SectionName) {
          item.TestCaseDetails = item.TestCaseDetails.map((testCase) => {
            const selectedItem =
              action.payload.caseId === testCase.CaseId
                ? !testCase.selected
                : testCase.selected;

            if (action.payload.caseId === testCase.CaseId) {
              isSelected = selectedItem;
              selectedItemAdded = testCase;
              selectedItemAdded.title = item.SectionName;
            }
            return {
              ...testCase,
              title: item.SectionName,
              selected: selectedItem,
            };
          });
        }

        return item;
      });

      if (!isSelected) {
        const newitems = state.selectedItems.filter((item) => {
          return action.payload.caseId !== item.CaseId;
        });
        state.selectedItems = newitems;
      } else {
        state.selectedItems = [...state.selectedItems, selectedItemAdded];
      }
    },
    cleanRunnedTest: (state) => {
      state.runnedTestAnswers = [];
      state.runedTestStatistic = {
        passed: 0,
        error: 0,
      };
    },
    setRunedTests: (state, action) => {
      state.runnedTestAnswers = [...state.runnedTestAnswers, action.payload];
    },
    setRunedTestsStatistic: (state, action) => {
      state.runedTestStatistic[action.payload.type] =
        state.runedTestStatistic[action.payload.type] + 1;
    },
    setSimpleTesting: (state, action) => {
      state.isSimple = action.payload;
    },
    setContentType: (state, action) => {
      state.contentType = action.payload;
    },
    setApiVersion: (state, action) => {
      state.apiVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTestCases.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.validations = action.payload;
        state.selectedItems = [];
      })
      .addCase(getTestCases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setAllSelected,
  selectSection,
  selectTestCase,
  cleanRunnedTest,
  setRunedTests,
  setRunedTestsStatistic,
  setSimpleTesting,
  setContentType,
  setApiVersion,
  setSelectedFilterType
} = dataSlice.actions;

export default dataSlice.reducer;
